<div class="faqs">
  <div class="title" i18n>Perguntas frequentes</div>

  <div class="accordion">
    <div class="accordion-item">
      <div
        class="accordion-item-header"
        (click)="accordionState[0] = !accordionState[0]">
        <h3 i18n>
          O que é o Canal de Denúncia do Governo Regional dos Açores?
        </h3>
        <img *ngIf="accordionState[0]" src="assets/images/minus.svg" />
        <img *ngIf="!accordionState[0]" src="assets/images/plus.svg" />
      </div>
      <div class="divider" *ngIf="accordionState[0]"></div>
      <div class="accordion-item-content" *ngIf="accordionState[0]">
        <div i18n class="text">
          O Canal de Denuncia do Governo Regional dos Açores integra o projeto
          “Atendimento ao Público APR – Nova Geração”, que pertence à medida
          “APR+ Acessível, Inclusiva e Aberta", que integra o investimento
          “Modernização e Digitalização da Administração Pública-RAA”, que
          pretende reforçar a eficácia e a eficiência, e valorizar o serviço
          público prestado nos Açores, centrando a atuação da Administração
          Pública nas atuais necessidades e expetativas dos cidadãos e das
          empresas. Este investimento está inserido na componente 19
          “Administração Pública mais Eficiente”, que pertence à dimensão
          “Transição Digital” do PRR-Açores.Este mecanismo deriva do Plano de
          Recuperação e Resiliência de Portugal (PRR), um programa, de âmbito
          nacional, que se enquadra no Mecanismo de Recuperação e Resiliência
          que está inserido no Next Generation UE.
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <div
        class="accordion-item-header"
        (click)="accordionState[1] = !accordionState[1]">
        <h3 i18n>
          Qual a legislação que protege os denunciantes de infrações?
        </h3>
        <img *ngIf="accordionState[1]" src="assets/images/minus.svg" />
        <img *ngIf="!accordionState[1]" src="assets/images/plus.svg" />
      </div>
      <div class="divider" *ngIf="accordionState[1]"></div>
      <div class="accordion-item-content" *ngIf="accordionState[1]">
        <div i18n class="text">
          A
          <a href="https://files.dre.pt/1s/2021/12/24400/0000300015.pdf"
            >Lei n.º 93/2021</a
          >, de 20 de dezembro estabelece o regime geral de proteção de
          denunciantes de infrações (RGPDI), transpondo a
          <a
            href="https://eur-lex.europa.eu/legal-content/PT/TXT/?uri=CELEX:32019L1937"
            >Diretiva (UE) 2019/1937 do Parlamento Europeu e do Conselho, de 23
            de outubro de 2019</a
          >, relativa à proteção das pessoas que denunciam violações do direito
          da União.
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <div
        class="accordion-item-header"
        (click)="accordionState[2] = !accordionState[2]">
        <h3 i18n>Denúncias - Quais são os meios de denúncia existentes?</h3>
        <img *ngIf="accordionState[2]" src="assets/images/minus.svg" />
        <img *ngIf="!accordionState[2]" src="assets/images/plus.svg" />
      </div>
      <div class="divider" *ngIf="accordionState[2]"></div>
      <div class="accordion-item-content" *ngIf="accordionState[2]">
        <div class="text" i18n>
          As denúncias de infrações são apresentadas pelo denunciante através
          dos canais de denúncia interna ou externa. A denúncia interna pode ser
          efetuada por pessoas que integram o serviço/entidade sobre a qual
          recai a denúncia. A denúncia externa pode ser efetuada por pessoas
          externas ao serviço/entidade sobre a qual recai a denúncia. O
          denunciante só pode recorrer a canais de denúncia externa quando:
        </div>
        <ul>
          <li i18n>Não exista canal de denúncia interna;</li>
          <li i18n>
            O canal de denúncia interna admita apenas a apresentação de
            denúncias por trabalhadores, não o sendo o denunciante;
          </li>
          <li i18n>
            Tenha motivos razoáveis para crer que a infração não pode ser
            eficazmente conhecida ou resolvida a nível interno ou que existe
            risco de retaliação;
          </li>
          <li i18n>
            Tenha inicialmente apresentado uma denúncia interna sem que lhe
            tenham sido comunicadas as medidas previstas ou adotadas na
            sequência da denúncia nos prazos previstos no artigo 11.º do Regime
            Geral de Proteção do Denunciante, aprovado pela
            <a href="https://files.dre.pt/1s/2021/12/24400/0000300015.pdf"
              >Lei n.º 93/2021, de 20 de dezembro</a
            >; ou
          </li>
          <li i18n>
            A infração constitua crime ou contraordenação punível com coima
            superior a 50 000 (euro).
          </li>
        </ul>
      </div>
    </div>
    <div class="accordion-item">
      <div
        class="accordion-item-header"
        (click)="accordionState[3] = !accordionState[3]">
        <h3 i18n>Apresentação de Denúncias - Quem pode denunciar?</h3>
        <img *ngIf="accordionState[3]" src="assets/images/minus.svg" />
        <img *ngIf="!accordionState[3]" src="assets/images/plus.svg" />
      </div>
      <div class="divider" *ngIf="accordionState[3]"></div>
      <div class="accordion-item-content" *ngIf="accordionState[3]">
        <div i18n class="text">
          Qualquer pessoa singular ou coletiva que possua, ou possa possuir,
          informação que seja, ou possa ser, relevante para o exercício das
          competências de cada Departamento do Governo Regional.O denunciante
          deve, preferencialmente, identificar-se. O Canal de Denúncias pode, no
          entanto, atender a denúncias remetidas anonimamente, se a relevância e
          credibilidade das mesmas o justificar.
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <div
        class="accordion-item-header"
        (click)="accordionState[4] = !accordionState[4]">
        <h3 i18n>Quem pode ser considerado denunciante?</h3>
        <img *ngIf="accordionState[4]" src="assets/images/minus.svg" />
        <img *ngIf="!accordionState[4]" src="assets/images/plus.svg" />
      </div>
      <div class="divider" *ngIf="accordionState[4]"></div>
      <div class="accordion-item-content" *ngIf="accordionState[4]">
        <ul>
          <li i18n>Os trabalhadores do setor privado, social ou público;</li>
          <li i18n>
            Os prestadores de serviços, contratantes, subcontratantes e
            fornecedores, bem como quaisquer pessoas que atuem sob a sua
            supervisão e direção;
          </li>
          <li i18n>
            Os titulares de participações sociais e as pessoas pertencentes a
            órgãos de administração ou de gestão ou a órgãos fiscais ou de
            supervisão de pessoas coletivas, incluindo membros não executivos;
          </li>
          <li i18n>
            Voluntários e estagiários, remunerados ou não remunerados.
          </li>
        </ul>
      </div>
    </div>
    <div class="accordion-item">
      <div
        class="accordion-item-header"
        (click)="accordionState[5] = !accordionState[5]">
        <h3 i18n>
          Para além dos denunciantes, a proteção conferida pela lei pode ser
          extensível a outras pessoas?
        </h3>
        <img *ngIf="accordionState[5]" src="assets/images/minus.svg" />
        <img *ngIf="!accordionState[5]" src="assets/images/plus.svg" />
      </div>
      <div class="divider" *ngIf="accordionState[5]"></div>
      <div class="accordion-item-content" *ngIf="accordionState[5]">
        <div i18n class="text">
          Sim. A proteção conferida pode ser extensível às seguintes pessoas:
        </div>
        <ul>
          <li i18n>
            Pessoa singular que auxilie o denunciante no procedimento de
            denúncia e cujo auxílio deva ser confidencial, incluindo
            representantes sindicais ou representantes dos trabalhadores;
          </li>
          <li i18n>
            Terceiro que esteja ligado ao denunciante, designadamente colega de
            trabalho ou familiar, e possa ser alvo de retaliação num contexto
            profissional;
          </li>
          <li i18n>
            Pessoas coletivas ou entidades equiparadas que sejam detidas ou
            controladas pelo denunciante, para as quais o denunciante trabalhe
            ou com as quais esteja de alguma forma ligado num contexto
            profissional.
          </li>
        </ul>
      </div>
    </div>
    <div class="accordion-item">
      <div
        class="accordion-item-header"
        (click)="accordionState[6] = !accordionState[6]">
        <h3 i18n>Como pode denunciar?</h3>
        <img *ngIf="accordionState[6]" src="assets/images/minus.svg" />
        <img *ngIf="!accordionState[6]" src="assets/images/plus.svg" />
      </div>
      <div class="divider" *ngIf="accordionState[6]"></div>
      <div class="accordion-item-content" *ngIf="accordionState[6]">
        <div i18n class="text">Por escrito:</div>
        <ul>
          <li i18n>
            Utilizando formulário próprio a que pode aceder aqui (<a
              (click)="complaint(true)"
              >denúncia interna</a
            >
            ou <a (click)="complaint(true)">denúncia externa</a>);
          </li>
          <li i18n>
            Por correio dirigido ao Departamento do Governo Regional, utilizando
            os endereços disponibilizados
            <a href="https://portal.azores.gov.pt/"
              >no portal do Governo Regional dos Açores</a
            >;
          </li>
          <li i18n>
            Por e-mail dirigido aos Departamentos do Governo Regional,
            utilizando os endereços disponibilizados
            <a href="https://portal.azores.gov.pt/"
              >no portal do Governo Regional dos Açores</a
            >;
          </li>
          <li i18n>
            Oportunamente será disponibilizado um contacto telefónico dedicado
            às denúncias.
          </li>
        </ul>
      </div>
    </div>
    <div class="accordion-item">
      <div
        class="accordion-item-header"
        (click)="accordionState[7] = !accordionState[7]">
        <h3 i18n>Objeto da denúncia - O que pode ser denunciado?</h3>
        <img *ngIf="accordionState[7]" src="assets/images/minus.svg" />
        <img *ngIf="!accordionState[7]" src="assets/images/plus.svg" />
      </div>
      <div class="divider" *ngIf="accordionState[7]"></div>
      <div class="accordion-item-content" *ngIf="accordionState[7]">
        <div i18n class="text">
          Através deste canal pode partilhar de forma anónima e/ou confidencial
          o seu conhecimento ou suspeita de uma irregularidade, ilegalidade ou
          prática de um crime na sua organização. A denúncia pode ter por
          objeto:
        </div>
        <ul>
          <li i18n>Infrações já cometidas;</li>
          <li i18n>Infrações que estejam a ser cometidas;</li>
          <li i18n>
            Infrações cujo cometimento se possa razoavelmente prever;
          </li>
          <li i18n>Tentativas de ocultação de tais infrações.</li>
        </ul>
        <div i18n class="text">
          Serão consideradas as denúncias relativas a matérias da competência de
          cada departamento governamental, designadamente factos relacionados
          com:
        </div>
        <ul>
          <li i18n>Contratação pública;</li>
          <li i18n>
            Licenciamentos e outros atos administrativos de concessão;
          </li>
          <li i18n>Boa gestão de bens, dinheiros e atividades públicas;</li>
          <li i18n>Proteção Ambiental;</li>
          <li i18n>Saúde Pública;</li>
          <li i18n>Segurança e conformidade dos produtos;</li>
          <li i18n>Defesa do consumidor;</li>
          <li i18n>
            Corrupção e infrações conexas (artigo 3.º do Regime Geral de
            Prevenção da Corrupção, aprovado em anexo ao
            <a
              href="https://pgdlisboa.pt/leis/lei_mostra_articulado.php?nid=3543&tabela=leis&ficha=1"
              >Decreto-Lei n.º 109-E/2021, de 9 de dezembro)</a
            >.
          </li>
          <li i18n>
            Proteção da privacidade e dos dados pessoais e segurança da rede e
            dos sistemas de informação;
          </li>
          <li i18n>Segurança dos transportes;</li>
          <li i18n>Outros.</li>
        </ul>
      </div>
    </div>
    <div class="accordion-item">
      <div
        class="accordion-item-header"
        (click)="accordionState[8] = !accordionState[8]">
        <h3 i18n>
          Quais os elementos necessários que devem constar da denúncia?
        </h3>
        <img *ngIf="accordionState[8]" src="assets/images/minus.svg" />
        <img *ngIf="!accordionState[8]" src="assets/images/plus.svg" />
      </div>
      <div class="divider" *ngIf="accordionState[8]"></div>
      <div class="accordion-item-content" *ngIf="accordionState[8]">
        <ul>
          <li i18n>
            Conter uma explicação o mais detalhada e objetiva possível sobre os
            factos e infração(ões), incluindo informação sobre datas ou períodos
            em que ocorreram, identificação das pessoas e entidades visadas e
            montantes em causa, quando aplicável;
          </li>
          <li i18n>
            Identificar outras pessoas que têm conhecimento dos factos ou podem
            ajudar a esclarecê-los e suportar a denúncia, sempre que possível,
            em prova documental ou outra (ver questão e resposta seguintes)
          </li>
        </ul>
      </div>
    </div>
    <div class="accordion-item">
      <div
        class="accordion-item-header"
        (click)="accordionState[9] = !accordionState[9]">
        <h3 i18n>
          É possível juntar anexos comprovativos (prova) dos factos relatados?
        </h3>
        <img *ngIf="accordionState[9]" src="assets/images/minus.svg" />
        <img *ngIf="!accordionState[9]" src="assets/images/plus.svg" />
      </div>
      <div class="divider" *ngIf="accordionState[9]"></div>
      <div class="accordion-item-content" *ngIf="accordionState[9]">
        <ul>
          <li i18n>
            Sim. Podem ser adicionados eventuais anexos que visem provar os
            factos relatados na denúncia e, assim, auxiliar no
            tratamento/seguimento da denúncia, em formato (pdf, jpeg, mp3, mp4);
          </li>
          <li i18n>
            No caso do(a) denunciante ter manifestado intenção de anonimato,
            deve assegurar que não inclui informações que possam revelar a sua
            identidade.
          </li>
        </ul>
      </div>
    </div>
    <div class="accordion-item">
      <div
        class="accordion-item-header"
        (click)="accordionState[10] = !accordionState[10]">
        <h3 i18n>
          Tratamento das denúncias apresentadas - Como será considerada a
          denúncia apresentada?
        </h3>
        <img *ngIf="accordionState[10]" src="assets/images/minus.svg" />
        <img *ngIf="!accordionState[10]" src="assets/images/plus.svg" />
      </div>
      <div class="divider" *ngIf="accordionState[10]"></div>
      <div class="accordion-item-content" *ngIf="accordionState[10]">
        <div i18n class="text">
          Os factos comunicados serão objeto de análise técnica pelos
          departamentos competentes e, em função da respetiva relevância, o
          responsável pela gestão das denúncias determinará de que forma serão
          considerados.
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <div
        class="accordion-item-header"
        (click)="accordionState[11] = !accordionState[11]">
        <h3 i18n>Procedimentos e prazos da denúncia</h3>
        <img *ngIf="accordionState[11]" src="assets/images/minus.svg" />
        <img *ngIf="!accordionState[11]" src="assets/images/plus.svg" />
      </div>
      <div class="divider" *ngIf="accordionState[11]"></div>
      <div class="accordion-item-content" *ngIf="accordionState[11]">
        <ul>
          <li i18n>
            <b
              >Obrigação de notificação do/a denunciante, no prazo de sete dias,
              a confirmar a receção da denúncia</b
            >, salvo pedido expresso em contrário do/a denunciante externo ou se
            existirem motivos razoáveis para crer que a notificação pode
            comprometer a proteção da sua identidade e, ainda, no caso de
            denúncia interna, se existir informação, de forma clara e acessível,
            sobre a possibilidade de apresentar uma denúncia externa, com
            indicação dos requisitos, das autoridades competentes e da forma e
            admissibilidade deste tipo de denúncia;
          </li>
          <li i18n>
            <b
              >Prática dos atos adequados à verificação das alegações da
              denúncia</b
            >
            e, se for caso disso, à cessação da infração denunciada (v.g.
            abertura de inquérito, de processo disciplinar ou da comunicação a
            autoridade competente);
          </li>
          <li i18n>
            Pode ser exigido que o/a denunciante complete ou clarifique as
            informações contidas na denúncia, fornecendo toda a documentação
            adicional necessária para sustentar os factos e a infração
            indiciada;
          </li>
          <li i18n>
            <b
              >Obrigação de comunicar ao denunciante, no prazo de máximo de
              três</b
            >
            meses a contar da data da receção da denúncia, sobre quais
            <b
              >as medidas previstas ou adotadas para dar seguimento à denúncia e
              a respetiva fundamentação</b
            >, sendo que o prazo pode ser alargado até seis meses, nos casos de
            maior complexidade e apenas nas denúncias externas;
          </li>
          <li i18n>
            <b
              >Possibilidade de o/a denunciante requerer que lhe seja comunicado
              o resultado</b
            >
            da análise efetuada à denúncia,
            <b>no prazo de quinze dias, após a respetiva conclusão.</b>
          </li>
        </ul>
      </div>
    </div>
    <div class="accordion-item">
      <div
        class="accordion-item-header"
        (click)="accordionState[12] = !accordionState[12]">
        <h3 i18n>
          Quais os motivos que podem originar o arquivamento das denúncias (a
          título exemplificativo)?
        </h3>
        <img *ngIf="accordionState[12]" src="assets/images/minus.svg" />
        <img *ngIf="!accordionState[12]" src="assets/images/plus.svg" />
      </div>
      <div class="divider" *ngIf="accordionState[12]"></div>
      <div class="accordion-item-content" *ngIf="accordionState[12]">
        <ul>
          <li i18n>
            <b
              >Não enquadramento dos factos relatados nas infrações e domínios
              tipificados na lei</b
            >
            (artigo 2.º do Regime Geral de Proteção dos Denunciantes, aprovado
            pela
            <a href="https://files.dre.pt/1s/2021/12/24400/0000300015.pdf"
              >Lei n.º 93/2021, de 20 de dezembro)</a
            >;
          </li>
          <li i18n>
            <b>Não apresentação de provas claras e inequívocas dos factos</b>
            que podem ser contrários à lei vigente;
          </li>
          <li i18n>
            <b
              >Não cumprimento dos requisitos/elementos mínimos de elaboração da
              denúncia e o seu autor não ter corrigido os erros/omissões</b
            >
            após ter sido solicitado para o fazer;
          </li>
          <li i18n>
            <b>Não ser a entidade competente para apreciar a denúncia,</b> caso
            em que se procederá ao envio para a entidade responsável;
          </li>
          <li i18n>
            <b
              >A situação já ter sido comunicada a uma autoridade judiciária ou
              a uma autoridade administrativa competente,</b
            >
            que a está a investigar ou que já adotou uma decisão anteriormente;
          </li>
          <li i18n>
            <b
              >A infração denunciada ser repetida e não conter novos
              elementos</b
            >
            que justifiquem um seguimento diferente de uma decisão anterior.
          </li>
        </ul>
      </div>
    </div>
    <div class="accordion-item">
      <div
        class="accordion-item-header"
        (click)="accordionState[13] = !accordionState[13]">
        <h3 i18n>Acompanhamento de Denúncia</h3>
        <img *ngIf="accordionState[13]" src="assets/images/minus.svg" />
        <img *ngIf="!accordionState[13]" src="assets/images/plus.svg" />
      </div>
      <div class="divider" *ngIf="accordionState[13]"></div>
      <div class="accordion-item-content" *ngIf="accordionState[13]">
        <div i18n class="text">A denúncia compreende os seguintes estados:</div>
        <ul>
          <li i18n>
            Submetida – quando a denúncia é submetida pelo denunciante. É-lhe
            atribuído um número de denúncia;
          </li>
          <li i18n>
            Em processo – quando o pedido é atribuído a um responsável pela
            gestão de denúncia para análise e tratamento;
          </li>
          <li i18n>
            Fechada – quando o responsável pela gestão de denúncia encerra uma
            denúncia, dando uma resposta ao denunciante;
          </li>
        </ul>
        <div i18n class="text">
          O pedido pode ser reaberto pelo denunciante, no prazo de 15 dias após
          ter sido fechada. Se reaberta, volta ao estado “em processo” e é
          atribuída ao responsável pela gestão da denúncia;
        </div>
        <ul>
          <li i18n>Arquivada – sem efeito/duplicada/erro no registo.</li>
        </ul>
      </div>
    </div>
    <div class="accordion-item">
      <div
        class="accordion-item-header"
        (click)="accordionState[14] = !accordionState[14]">
        <h3 i18n>Quais os direitos dos/as denunciantes?</h3>
        <img *ngIf="accordionState[14]" src="assets/images/minus.svg" />
        <img *ngIf="!accordionState[14]" src="assets/images/plus.svg" />
      </div>
      <div class="divider" *ngIf="accordionState[14]"></div>
      <div class="accordion-item-content" *ngIf="accordionState[14]">
        <ul>
          <li i18n>
            Direito à confidencialidade da sua identidade, bem como das
            informações que, direta ou indiretamente, permitam deduzi-la;
          </li>
          <li i18n>Direito a proteção jurídica nos termos gerais;</li>
          <li i18n>Proibição de atos de retaliação;</li>
          <li i18n>
            Direito a beneficiar de medidas de proteção de testemunhas em
            processo penal;
          </li>
          <li i18n>
            A proteção conferida pelo regime é extensível a terceiros que
            auxiliem ou estejam ligados ao denunciante.
          </li>
        </ul>
        <div i18n class="text">
          Previstos nos artigos 18.º, 19.º e 22.º a 24, do Regime Geral de
          Proteção dos Denunciantes, aprovado pela
          <a href="https://files.dre.pt/1s/2021/12/24400/0000300015.pdf"
            >Lei n.º 93/2021, de 20 de dezembro</a
          >.
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <div
        class="accordion-item-header"
        (click)="accordionState[15] = !accordionState[15]">
        <h3 i18n>
          Quais as condições em que o/a denunciante não incorre em
          responsabilidade por violação de deveres de confidencialidade ou
          outros?
        </h3>
        <img *ngIf="accordionState[15]" src="assets/images/minus.svg" />
        <img *ngIf="!accordionState[15]" src="assets/images/plus.svg" />
      </div>
      <div class="divider" *ngIf="accordionState[15]"></div>
      <div class="accordion-item-content" *ngIf="accordionState[15]">
        <ul>
          <li i18n>
            Desde que atue nos termos da lei, a denúncia não constituirá
            fundamento para responsabilidade disciplinar, civil,
            contraordenacional ou criminal;
          </li>
          <li i18n>
            Desde que o acesso ou a obtenção da informação que consta da
            denúncia tenha sido legítimo, isto é, não constitua crime;
          </li>
          <li i18n>
            Desde que não prejudique o dever de confidencialidade da identidade
            das pessoas visadas ou que a ela sejam associadas e, em especial, a
            presunção da inocência e as garantias de defesa legalmente
            reconhecidas.
          </li>
        </ul>
      </div>
    </div>
    <div class="accordion-item">
      <div
        class="accordion-item-header"
        (click)="accordionState[16] = !accordionState[16]">
        <h3 i18n>
          Em que situações o/a denunciante não beneficia de proteção/pode ser
          responsabilizado/a?
        </h3>
        <img *ngIf="accordionState[16]" src="assets/images/minus.svg" />
        <img *ngIf="!accordionState[16]" src="assets/images/plus.svg" />
      </div>
      <div class="divider" *ngIf="accordionState[16]"></div>
      <div class="accordion-item-content" *ngIf="accordionState[16]">
        <ul>
          <li i18n>
            Não cumprimento intencional dos requisitos impostos pelo Regime
            Geral de Proteção dos Denunciantes, aprovado pela
            <a href="https://files.dre.pt/1s/2021/12/24400/0000300015.pdf"
              >Lei n.º 93/2021, de 20 de dezembro</a
            >, na denúncia de infrações.
          </li>
        </ul>
        <div i18n class="text">Exemplos:</div>
        <ul>
          <li i18n>
            Comunicar ou divulgar publicamente informações falsas (alínea d), do
            n.º 1, do artigo 27.º do Regime Geral de Proteção dos Denunciantes,
            aprovado pela
            <a href="https://files.dre.pt/1s/2021/12/24400/0000300015.pdf"
              >Lei n.º 93/2021, de 20 de dezembro</a
            >);
          </li>
          <li i18n>
            Obter ou aceder às informações ou aos documentos, através da prática
            de um crime, como a invasão da propriedade alheia ou a pirataria
            informática (n.º 92 da
            <a
              href="https://eur-lex.europa.eu/legal-content/PT/TXT/?uri=CELEX:32019L1937"
              >Diretiva UE 2019/1937, de 23/10/2019</a
            >);
          </li>
          <li i18n>
            Não observância culposa das regras de precedência, previstas nas
            alíneas a) a e), do n.º 2, do artigo 7.º, do Regime Geral de
            Proteção dos Denunciantes, aprovado pela
            <a href="https://files.dre.pt/1s/2021/12/24400/0000300015.pdf"
              >Lei n.º 93/2021, de 20 de dezembro</a
            >, no momento da apresentação de denúncia externa;
          </li>
          <li i18n>
            Prática de atos ou omissões não relacionados com a denúncia ou que
            não sejam necessários à denúncia de uma infração, nos termos
            previstos no Regime Geral de Proteção dos Denunciantes, aprovado
            pela
            <a href="https://files.dre.pt/1s/2021/12/24400/0000300015.pdf"
              >Lei n.º 93/2021, de 20 de dezembro</a
            >;
          </li>
          <li i18n>
            Comunicação/denúncia efetuada constitua em si mesmo a prática de um
            crime.
          </li>
        </ul>
      </div>
    </div>
    <div class="accordion-item">
      <div
        class="accordion-item-header"
        (click)="accordionState[17] = !accordionState[17]">
        <h3 i18n>
          Como é efetuado o tratamento dos dados pessoais e a conservação de
          documentos?
        </h3>
        <img *ngIf="accordionState[17]" src="assets/images/minus.svg" />
        <img *ngIf="!accordionState[17]" src="assets/images/plus.svg" />
      </div>
      <div class="divider" *ngIf="accordionState[17]"></div>
      <div class="accordion-item-content" *ngIf="accordionState[17]">
        <ul>
          <li i18n>
            A informação comunicada será utilizada exclusivamente para as
            finalidades legais previstas para o canal interno ou externo, no
            estrito cumprimento do disposto no Regulamento Geral de Proteção de
            Dados e na Política de Privacidade do Governo Regional dos Açores
            (GRA), que pode ser consultado
            <a href="https://www.igf.gov.pt/politica-de-privacidade.aspx"
              >aqui</a
            >.
          </li>
          <li i18n>
            A documentação de apoio e os dados recolhidos durante a triagem e a
            investigação serão arquivados respeitando a sua confidencialidade e
            segurança.
          </li>
          <li i18n>
            Serão adotadas medidas de segurança no arquivo da informação, por
            forma a restringir o seu acesso apenas a pessoas autorizadas.
          </li>
        </ul>
      </div>
    </div>
    <div class="accordion-item">
      <div
        class="accordion-item-header"
        (click)="accordionState[18] = !accordionState[18]">
        <h3 i18n>Qual o prazo de conservação das denúncias?</h3>
        <img *ngIf="accordionState[18]" src="assets/images/minus.svg" />
        <img *ngIf="!accordionState[18]" src="assets/images/plus.svg" />
      </div>
      <div class="divider" *ngIf="accordionState[18]"></div>
      <div class="accordion-item-content" *ngIf="accordionState[18]">
        <div i18n class="text">
          As denúncias recebidas são mantidas e conservadas durante o período
          de, pelo menos, cinco anos e, independentemente desse prazo, durante a
          pendência de processos judiciais, administrativos ou disciplinares
          relacionados.
        </div>
      </div>
    </div>
  </div>
</div>
