import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EntityService {
  constructor(private http: HttpClient) {}

  getData(): Observable<any> {
    return this.http.post<any>(`${environment.base_url}entity/getEntityByUrl`, {
      url: window.location.origin,
    });
  }
}
