<div class="contacts">
  <div i18n class="title">Contactos</div>
  <!-- <div class="subtitle">
    Lörem ipsum mobilmobbning bina var. Teleliga däpalingar divis tigen.
    Antehade ösåpussade. Lassa prokrongar tåligt.
  </div> -->
  <div class="contact">
    <img src="assets/images/email.svg" alt="email" />
    <div class="contact-description">
      <div i18n class="contact-title">Email</div>
      <div class="contact-value">cda.suporte@azores.gov.pt</div>
    </div>
  </div>
</div>
