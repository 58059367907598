import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-welcome',
  templateUrl: './home-welcome.component.html',
  styleUrls: ['./home-welcome.component.scss'],
})
export class HomeWelcomeComponent implements OnInit {
  entityText: string;
  complaintText: string;

  constructor(private router: Router) {
    this.entityText = $localize`O denunciante é trabalhador da entidade.`;
    this.complaintText = $localize`Independente da qualidade do denunciante.`;
  }

  ngOnInit(): void {}

  complaint(internal: boolean) {
    this.router.navigate(['/complaint'], {
      state: { internal },
    });
  }
}
