<div class="navbar" [class.divider]="checkPage()">
  <div class="menu-toggle" (click)="toggleMenu()">
    <div class="menu-line"></div>
    <div class="menu-line"></div>
    <div class="menu-line"></div>
  </div>
  <div class="logo">
    <img
      src="assets/images/gra-logo.svg"
      loading="lazy"
      class="img-logo"
      [routerLink]="['/home']" />
  </div>

  <div class="link-wrapper">
    <ng-container *ngTemplateOutlet="menu"></ng-container>
  </div>
  <div class="right-wrapper">
    <div
      class="complaint-button"
      [routerLink]="['/follow']"
      *ngIf="showButtonForm">
      <p i18n>Acompanhar denúncia</p>
    </div>
    <div class="translations" (click)="open = !open">
      <img src="assets/images/languages.svg" />
      <img src="assets/images/languages_arrow_down.svg" *ngIf="!open" />
      <img src="assets/images/languages_arrow_up.svg" *ngIf="open" />
      <div class="language-options" *ngIf="open">
        <a class="link" (click)="changeLanguage('pt')">
          <div class="flag-pt"></div>
          <div class="text">Português</div>
        </a>
        <a class="link" (click)="changeLanguage('en')">
          <div class="flag-en"></div>
          <div class="text">English</div>
        </a>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="isMenuOpen">
  <div class="link-wrapper" [class.show]="isMenuOpen">
    <ng-container *ngTemplateOutlet="menu"></ng-container>
  </div>
</ng-container>

<ng-template #menu>
  <nav>
    <ul>
      <li *ngFor="let link of links">
        <a
          [routerLink]="[link.path]"
          class="navbar-link"
          (click)="closeMenu()"
          >{{ link.label }}</a
        >
      </li>
    </ul>
  </nav>
</ng-template>
