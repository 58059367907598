import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { EntityService } from './services/entity.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private entityService: EntityService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.entityService.getData().subscribe(data => {
      this.setThemeColors(data.primaryColor, data.secondaryColor);
    });

    const currentLanguage = this.getCurrentLanguage();
    const previousLanguage = localStorage.getItem(
      'portal_do_denunciante_idioma'
    );
    const internal =
      localStorage.getItem('portal_do_denunciante_tipo') == 'interno'
        ? true
        : false;

    this.route.fragment.subscribe(fragment => {
      if (!fragment) return;

      const codeCmd = new URLSearchParams(fragment).get('access_token');
      if (
        codeCmd &&
        currentLanguage &&
        previousLanguage &&
        previousLanguage != currentLanguage
      ) {
        window.open(
          window.location.origin +
            `/portal/${previousLanguage}#access_token=${codeCmd}`,
          '_self'
        );
      } else if (codeCmd) {
        this.router.navigate(['/complaint'], {
          state: { codeCmd, internal },
        });
      }
    });

    this.route.queryParams.subscribe(params => {
      const code = params['code'];

      const method = localStorage.getItem('portal_do_denunciante_metodo');

      if (
        code &&
        currentLanguage &&
        previousLanguage &&
        previousLanguage != currentLanguage
      ) {
        window.open(
          window.location.origin + `/portal/${previousLanguage}?code=${code}`,
          '_self'
        );
      } else if (code) {
        this.router.navigate(['/complaint'], {
          state: { code, internal, method },
        });
      }
    });
  }

  getCurrentLanguage(): string {
    const path = window.location.pathname;
    const splitPath = path.split('/');
    return splitPath[2] ?? '';
  }

  setThemeColors(primary: string, secondary: string) {
    document.documentElement.style.setProperty('--primary-color', primary);
    document.documentElement.style.setProperty('--secondary-color', secondary);
  }
}
