import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-faqs',
  templateUrl: './home-faqs.component.html',
  styleUrls: ['./home-faqs.component.scss'],
})
export class HomeFaqsComponent implements OnInit {
  accordionState: Array<boolean> = [true, false, false];

  constructor() {}

  ngOnInit(): void {}
}
