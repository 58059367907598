<div class="page">
  <div i18n class="title">Política de Privacidade</div>

  <div class="terms">
    <div i18n class="sub-title">Política de Privacidade</div>
    <div i18n class="text">
      O Sítio (<a href="https://canaldenuncias.azores.gov.pt"
        >https://canaldenuncias.azores.gov.pt</a
      >) assegura aos seus utilizadores privacidade e segurança nos dados
      pessoais facultados, sendo apenas solicitados e recolhidos os dados
      pessoais necessários às finalidades próprias do mesmo. É igualmente
      permitida a submissão de denúncias de forma totalmente anonimizada, sendo
      que neste caso não são registados quaisquer dados pessoais. Caso o
      requeira, o titular dos dados pessoais tem o direito à consulta, à
      retificação, à anonimização e à limitação do tratamento dos dados pessoais
      facultados, bem como o direito de reclamação. São assumidos para com os
      utilizadores do Sítio os seguintes compromissos:
    </div>
    <ul>
      <li i18n>
        Permitir ao titular dos dados pessoais o acesso e correção das
        informações sobre si registadas, transmitindo-as em linguagem clara e
        rigorosamente correspondente ao conteúdo do registo;
      </li>
      <li i18n>Manter os dados exatos e, se necessário, atuais;</li>
      <li i18n>
        Assegurar a anonimização dos dados pessoais sempre que tal for exigido;
      </li>
      <li i18n>
        Garantir o acesso e controlo dos dados pessoais num formato estruturado,
        de uso corrente e atualizado, de leitura automática e interoperável;
      </li>
      <li i18n>
        Ter sistemas de segurança que impeçam a consulta, modificação,
        destruição ou adição dos dados por pessoa não autorizada a fazê-lo e que
        permitam detetar desvios de informação intencionais ou não;
      </li>
      <li i18n>
        Respeitar o sigilo profissional em relação aos dados pessoais tratados;
      </li>
      <li i18n>
        Não proceder a qualquer transmissão de dados pessoais para fins
        comerciais, publicitários ou quaisquer outros fins.
      </li>
    </ul>
    <div i18n class="text">
      O utilizador compromete-se a notificar qualquer alteração ou modificação
      dos dados pessoais que tenha facultado. Qualquer perda ou dano causado ao
      Site ou a qualquer terceiro através da comunicação de informação errada,
      inexata ou incompleta será da responsabilidade única e exclusiva do
      utilizador. É igualmente assegurado o cumprimento do disposto na Lei n.º
      93/2021, de 21 de dezembro em matéria específica dos canais de denúncia.
    </div>

    <div i18n class="sub-title">Responsável pelo Tratamento de Dados</div>
    <div i18n class="text">
      O tratamento de dados pessoais será realizado pela entidade do Governo
      Regional recetora da denúncia, em conformidade com o Regulamento Geral de
      Proteção de Dados, aprovado pelo Regulamento (UE) 2016/679 do Parlamento
      Europeu e do Conselho, de 27 de abril de 2016 e com a Lei nacional dos
      Dados Pessoais, Lei n.º 58/2019, de 08 de agosto, que opera a sua
      adaptação nacional, remetendo-se as questões relativas ao tratamento de
      dados para o endereço eletrónico srfpap@azores.gov.pt. Não serão
      realizados quaisquer tratamentos de dados nos casos que envolvem denúncias
      anónimas.
    </div>
    <div i18n class="sub-title">Encarregado de proteção de dados</div>
    <div i18n class="text">
      De forma a assegurar as relações com os titulares dos dados nas matérias
      abrangidas pelo RGPD e pela legislação nacional em matéria de proteção de
      dados, assim como outras obrigações legalmente previstas, o Encarregado de
      Proteção de Dados da Secretaria Regional das Finanças, Planeamento e
      Administração Pública poderá ser contactado por correio eletrónico para o
      endereço srfpap.epd@azores.gov.pt.
    </div>
    <div i18n class="sub-title">Violação de dados pessoais</div>
    <div i18n class="text">
      Em cumprimento do disposto no Regulamento Geral de Proteção de Dados,
      aprovado pelo Regulamento (EU) 2016/679 do Parlamento Europeu e do
      Conselho, de 27 de abril de 2016, será alertada a Comissão Nacional de
      Proteção de Dados (CNPD) ou outras que venham a ser legalmente
      constituídas na eventualidade de violação de dados pessoais, no prazo
      legalmente previsto.
    </div>
    <div i18n class="sub-title">Tipologia de dados pessoais</div>
    <div i18n class="text">
      Os dados pessoais sujeitos a tratamento por parte do presente Portal são
      de diversa natureza atenta a prestação de serviços promovidos nas
      plataformas eletrónicas sob a sua gestão. De acordo com o tipo de serviços
      prestados podem ser recolhidos os seguintes dados pessoais na sequência de
      denúncia: Nome, contacto, dados pessoais inseridos no formulário. Para
      segurança e cumprimento do princípio da minimização dos dados pessoais
      recomenda-se que no formulário sejam facultados somente os dados pessoais
      indispensáveis ao cumprimento da Lei n.º 93/2021, de 21 de dezembro.
    </div>
    <div i18n class="sub-title">Segurança e utilização da informação</div>
    <div i18n class="text">
      Os dados pessoais recolhidos destinam-se à operacionalização do Portal do
      Canal de Denúncia do Governo Regional, findo o qual, os dados pessoais
      fornecidos apenas terão finalidades estatísticas, como é o caso de
      estudos, análises ou investigações relativamente à execução dos projetos,
      distribuição geográfica, bem como outras informações pertinentes ao
      melhoramento dos serviços. Os membros que gerem o presente portal estão
      obrigados à confidencialidade relativamente aos dados pessoais aos quais
      tenham acesso, estando devidamente informados da importância do
      cumprimento desse dever legal de sigilo e sendo responsáveis pelo seu
      incumprimento. O Governo Regional dos Açores compromete-se a garantir a
      qualidade e integridade da informação fornecida pelos utilizadores do
      Sítio, tendo para isso implementado as medidas necessárias, tanto ao nível
      tecnológico como organizacional, de forma a que se mantenha uma informação
      segura, precisa, atualizada e completa. Os dados consultados no Sítio e os
      enviados pelo utilizador para o Sítio são cifrados, quer a nível de
      comunicações, base de dados e do próprio portal.
    </div>
    <div i18n class="sub-title">Conteúdos</div>
    <div i18n class="text">
      A SRFPAP reserva-se no direito de remover textos e outros conteúdos que,
      direta ou indiretamente, incluam: Conteúdo incompreensível; Mensagens com
      fins comerciais ou de publicidade; Conteúdo copiado de terceiros ou que de
      alguma forma viole direitos de propriedade intelectual; HTML, vírus,
      código malicioso ou malware
    </div>
    <div i18n class="sub-title">Tecnologia</div>
    <div i18n class="text">
      As linguagens de programação usadas são Javascript, HTML e CSS.
    </div>
    <div i18n class="sub-title">Contacto</div>
    <div i18n class="text">
      Para esclarecimentos adicionais, por favor contacte-nos pelo endereço
      eletrónico
      <a href="mailto:cda.suporte@azores.gov.pt">cda.suporte@azores.gov.pt</a>
    </div>
  </div>
</div>
