<div class="faqs">
  <h1 i18n>Perguntas frequentes</h1>

  <button i18n [routerLink]="['/faqs']">Ver todas as perguntas</button>

  <div class="accordion">
    <div class="accordion-item">
      <div
        class="accordion-item-header"
        (click)="accordionState[0] = !accordionState[0]">
        <h3 i18n>
          Qual a legislação que protege os denunciantes de infrações?
        </h3>
        <img *ngIf="accordionState[0]" src="assets/images/minus.svg" />
        <img *ngIf="!accordionState[0]" src="assets/images/plus.svg" />
      </div>
      <div class="divider" *ngIf="accordionState[0]"></div>
      <div class="accordion-item-content" *ngIf="accordionState[0]">
        <div i18n class="text">
          A
          <a href="https://files.dre.pt/1s/2021/12/24400/0000300015.pdf"
            >Lei n.º 93/2021</a
          >, de 20 de dezembro estabelece o regime geral de proteção de
          denunciantes de infrações (RGPDI), transpondo a
          <a
            href="https://eur-lex.europa.eu/legal-content/PT/TXT/?uri=CELEX:32019L1937"
            >Diretiva (UE) 2019/1937 do Parlamento Europeu e do Conselho, de 23
            de outubro de 2019</a
          >, relativa à proteção das pessoas que denunciam violações do direito
          da União.
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <div
        class="accordion-item-header"
        (click)="accordionState[1] = !accordionState[1]">
        <h3 i18n>Denúncias - Quais são os meios de denúncia existentes?</h3>
        <img *ngIf="accordionState[1]" src="assets/images/minus.svg" />
        <img *ngIf="!accordionState[1]" src="assets/images/plus.svg" />
      </div>
      <div class="divider" *ngIf="accordionState[1]"></div>
      <div class="accordion-item-content" *ngIf="accordionState[1]">
        <div class="text" i18n>
          As denúncias de infrações são apresentadas pelo denunciante através
          dos canais de denúncia interna ou externa. A denúncia interna pode ser
          efetuada por pessoas que integram o serviço/entidade sobre a qual
          recai a denúncia. A denúncia externa pode ser efetuada por pessoas
          externas ao serviço/entidade sobre a qual recai a denúncia. O
          denunciante só pode recorrer a canais de denúncia externa quando:
        </div>
        <ul>
          <li i18n>Não exista canal de denúncia interna;</li>
          <li i18n>
            O canal de denúncia interna admita apenas a apresentação de
            denúncias por trabalhadores, não o sendo o denunciante;
          </li>
          <li i18n>
            Tenha motivos razoáveis para crer que a infração não pode ser
            eficazmente conhecida ou resolvida a nível interno ou que existe
            risco de retaliação;
          </li>
          <li i18n>
            Tenha inicialmente apresentado uma denúncia interna sem que lhe
            tenham sido comunicadas as medidas previstas ou adotadas na
            sequência da denúncia nos prazos previstos no artigo 11.º do Regime
            Geral de Proteção do Denunciante, aprovado pela
            <a href="https://files.dre.pt/1s/2021/12/24400/0000300015.pdf"
              >Lei n.º 93/2021, de 20 de dezembro</a
            >; ou
          </li>
          <li i18n>
            A infração constitua crime ou contraordenação punível com coima
            superior a 50 000 (euro).
          </li>
        </ul>
      </div>
    </div>
    <div class="accordion-item">
      <div
        class="accordion-item-header"
        (click)="accordionState[2] = !accordionState[2]">
        <h3 i18n>Apresentação de Denúncias - Quem pode denunciar?</h3>
        <img *ngIf="accordionState[2]" src="assets/images/minus.svg" />
        <img *ngIf="!accordionState[2]" src="assets/images/plus.svg" />
      </div>
      <div class="divider" *ngIf="accordionState[2]"></div>
      <div class="accordion-item-content" *ngIf="accordionState[2]">
        <div i18n class="text">
          Qualquer pessoa singular ou coletiva que possua, ou possa possuir,
          informação que seja, ou possa ser, relevante para o exercício das
          competências de cada Departamento do Governo Regional.O denunciante
          deve, preferencialmente, identificar-se. O Canal de Denúncias pode, no
          entanto, atender a denúncias remetidas anonimamente, se a relevância e
          credibilidade das mesmas o justificar.
        </div>
      </div>
    </div>
  </div>
</div>
