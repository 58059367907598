import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  open: boolean = false;
  isMenuOpen: boolean;
  links: { path: string; label: string }[];
  divider: boolean;
  showButtonForm: boolean;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.isMenuOpen = false;
    this.divider = true;
    this.showButtonForm = true;

    this.links = [
      { path: '/home', label: $localize`Página Inicial` },
      { path: '/faqs', label: $localize`Perguntas frequentes` },
    ];
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  closeMenu(): void {
    this.isMenuOpen = false;
  }

  checkPage(): boolean {
    this.divider = this.router.url === '/home';
    return !this.divider;
  }

  changeLanguage(language: string) {
    if (language === 'en') {
      window.open('/portal/en/', '_self');
    } else {
      window.open('/portal/pt/', '_self');
    }
  }
}
