<div class="page">
  <div class="title" i18n>Termos e Condições</div>

  <div class="terms">
    <div class="sub-title" i18n>Termos e condições</div>
    <div class="text" i18n>
      Os seguintes termos e condições regulam qualquer utilização do Sítio de
      internet do Portal do Denunciante de todo o Governo Regional dos Açores e
      todo o seu conteúdo e serviços disponíveis em/ou através do domínio
      <a href="https://canaldenuncias.azores.gov.pt"
        >https://canaldenuncias.azores.gov.pt</a
      >
      ("Sítio"). A coordenação do presente Portal é da competência da Secretaria
      Regional das Finanças, Planeamento e Administração Pública (SRFPAP), a
      qual é responsável pela sua operacionalização e manutenção. A utilização
      do presente Portal está sujeita à sua aceitação sem modificação dos termos
      legais aqui contidos ou outras regras operacionais, políticas e
      procedimentos que possam ser publicados ocasionalmente neste Sítio, sem
      prejuízo do disposto na Lei n.º 93/2021, de 21 de dezembro, na sua atual
      versão. Deverá ler atentamente os Termos, considerando-se que o utilizador
      os aceita caso aceda ou utilize o Sítio, sem necessidade de qualquer ato
      ou consentimento posterior. Os Termos poderão ser alterados em qualquer
      momento pela SRFPAP, vigorando as alterações a partir da data da sua
      disponibilização no Sítio. O acesso e a utilização posteriores do Sítio
      pelo utilizador serão considerados como um sinal inequívoco de que o mesmo
      leu, aceitou e compreendeu os Termos alterados.
    </div>

    <div i18n class="sub-title">
      Declaração de exoneração de responsabilidade
    </div>
    <div i18n class="text">
      A SRFPAP gere o presente Sítio com o objetivo de possibilitar o acesso dos
      cidadãos a todas funcionalidades do Portal do Canal de Denúncias, todavia,
      não se assegura nem se declara que os ficheiros disponíveis para download
      no Sítio estejam completamente imunes à possibilidade de ameaças
      informáticas.
    </div>
    <div i18n class="sub-title">Acesso e alterações ao Sítio</div>
    <div i18n class="text">
      A SRFPAP reserva-se no direito de suspender, modificar, adicionar ou
      retirar parte dos conteúdos do Sítio, bem como estabelecer áreas restritas
      de acesso.
    </div>
    <div i18n class="sub-title">Direitos de autor</div>
    <div i18n class="text">
      Os conteúdos e o design do presente Sítio são propriedade do Governo
      Regional dos Açores. A reprodução de conteúdos é autorizada mediante
      indicação da fonte, salvo indicação em contrário.
    </div>
    <div i18n class="sub-title">Utilização indevida</div>
    <div i18n class="text">
      É proibida a utilização de conteúdos do Sítio para fins ilegais ou
      quaisquer outros fins que possam ser considerados indignos ou abusivos.
      Proíbe-se, ainda, a criação ou introdução neste sítio de qualquer tipo de
      vírus ou outro código ou programa que contenham propriedades destrutivas
      ou prejudiciais ao funcionamento do Sítio web.
    </div>
  </div>
</div>
