import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Complaint } from '../shared/models/complaint.model';

@Injectable({
  providedIn: 'root',
})
export class ComplaintService {
  constructor(private http: HttpClient) {}

  create(body: any): Observable<any> {
    return this.http.post<any>(
      `${environment.base_url}complaint/complaint`,
      body
    );
  }

  find(followNumber: string): Observable<Complaint> {
    return this.http.post<Complaint>(
      `${environment.base_url}complaint/getPublicComplaint`,
      {
        code: followNumber,
      }
    );
  }

  requestAnalysis(followNumber: string): Observable<Complaint> {
    return this.http.post<Complaint>(
      `${environment.base_url}complaint/requestAnalysis`,
      {
        code: followNumber,
      }
    );
  }
}
