import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ComplaintService } from 'src/app/services/complaint.service';
import { EntityService } from 'src/app/services/entity.service';
import FileToUpload from 'src/app/shared/models/fileToUpload';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.scss'],
})
export class ComplaintComponent implements OnInit {
  azoridEnabled: boolean = environment.azorid_enabled;

  groups: Array<any> = [];
  areas: Array<any> = [];
  domains: Array<any> = [];

  complaintForm!: FormGroup<any>;

  complainantDataFilled: boolean = false;
  submiting: boolean = false;
  followNumber: string = '';

  internal: boolean | null = null;
  code: string | null = null;
  method: string | null = null;
  codeCmd: string | null = null;

  today: Date = new Date();

  attachments: Array<FileToUpload> = [];

  // prettier-ignore
  pattern: string = "^[A-zÀ-ú_\\[\\]\/\"#$%&!=\(\)@£§«»?| \{\},.;:<>\\\\€\*\+\-]*";

  constructor(
    private entityService: EntityService,
    private complaintService: ComplaintService,
    private router: Router,
    private authorizationService: AuthorizationService
  ) {
    this.internal =
      this.router.getCurrentNavigation()?.extras.state?.['internal'];
    this.code = this.router.getCurrentNavigation()?.extras.state?.['code'];
    this.method = this.router.getCurrentNavigation()?.extras.state?.['method'];
    this.codeCmd =
      this.router.getCurrentNavigation()?.extras.state?.['codeCmd'];
  }

  ngOnInit() {
    this.entityService.getData().subscribe(data => {
      this.groups = data?.groups;
      this.getDomains();
    });

    if (this.internal) {
      this.complaintForm = new FormGroup<any>({
        recaptchaReactive: new FormControl(null, Validators.required),
        anonymous: new FormControl(false, Validators.required),
        internal: new FormControl(true, [Validators.required]),
        area: new FormControl('', [Validators.required]),
        peopleInvolved: new FormControl('', Validators.pattern(this.pattern)),
        description: new FormControl('', [
          Validators.required,
          Validators.maxLength(4000),
        ]),
        dateOfOccurrence: new FormControl('', [Validators.required]),
        complainant: new FormGroup({
          name: new FormControl(''),
          identificationNumber: new FormControl(''),
          email: new FormControl('', [
            Validators.required,
            this.emailValidator,
          ]),
        }),
      });
    } else {
      this.complaintForm = new FormGroup<any>({
        recaptchaReactive: new FormControl(null, Validators.required),
        anonymous: new FormControl(false, Validators.required),
        internal: new FormControl(false, [Validators.required]),
        domain: new FormControl('', [Validators.required]),
        peopleInvolved: new FormControl('', Validators.pattern(this.pattern)),
        description: new FormControl('', [
          Validators.required,
          Validators.maxLength(4000),
        ]),
        dateOfOccurrence: new FormControl('', [Validators.required]),
        complainant: new FormGroup({
          name: new FormControl(''),
          identificationNumber: new FormControl(''),
          email: new FormControl('', [
            Validators.required,
            this.emailValidator,
          ]),
        }),
      });
    }

    if (this.code && this.method === 'gra') {
      this.authorizationService.getUserInfo(this.code).subscribe(data => {
        if (this.internal) {
          this.complaintForm = new FormGroup<any>({
            recaptchaReactive: new FormControl(null, Validators.required),
            anonymous: new FormControl(false, Validators.required),
            internal: new FormControl(true, [Validators.required]),
            area: new FormControl('', [Validators.required]),
            peopleInvolved: new FormControl(
              '',
              Validators.pattern(this.pattern)
            ),
            description: new FormControl('', [
              Validators.required,
              Validators.maxLength(4000),
            ]),
            dateOfOccurrence: new FormControl('', [Validators.required]),
            complainant: new FormGroup({
              name: new FormControl(data.displayName),
              identificationNumber: new FormControl(''),
              email: new FormControl(data.sub, [
                Validators.required,
                this.emailValidator,
              ]),
            }),
          });
        } else {
          this.complaintForm = new FormGroup<any>({
            recaptchaReactive: new FormControl(null, Validators.required),
            anonymous: new FormControl(false, Validators.required),
            internal: new FormControl(false, [Validators.required]),
            domain: new FormControl('', [Validators.required]),
            peopleInvolved: new FormControl(
              '',
              Validators.pattern(this.pattern)
            ),
            description: new FormControl('', [
              Validators.required,
              Validators.maxLength(4000),
            ]),
            dateOfOccurrence: new FormControl('', [Validators.required]),
            complainant: new FormGroup({
              name: new FormControl(data.displayName),
              identificationNumber: new FormControl(''),
              email: new FormControl(data.sub, [
                Validators.required,
                this.emailValidator,
              ]),
            }),
          });
        }
      });
    } else if (this.code && this.method === 'azorid') {
      this.authorizationService.getUserInfoAzorid(this.code).subscribe(data => {
        if (this.internal) {
          this.complaintForm = new FormGroup<any>({
            recaptchaReactive: new FormControl(null, Validators.required),
            anonymous: new FormControl(false, Validators.required),
            internal: new FormControl(true, [Validators.required]),
            area: new FormControl('', [Validators.required]),
            peopleInvolved: new FormControl(
              '',
              Validators.pattern(this.pattern)
            ),
            description: new FormControl('', [
              Validators.required,
              Validators.maxLength(4000),
            ]),
            dateOfOccurrence: new FormControl('', [Validators.required]),
            complainant: new FormGroup({
              name: new FormControl(data.azi_fullName),
              identificationNumber: new FormControl(''),
              email: new FormControl(data.email, [
                Validators.required,
                this.emailValidator,
              ]),
            }),
          });
        } else {
          this.complaintForm = new FormGroup<any>({
            recaptchaReactive: new FormControl(null, Validators.required),
            anonymous: new FormControl(false, Validators.required),
            internal: new FormControl(false, [Validators.required]),
            domain: new FormControl('', [Validators.required]),
            peopleInvolved: new FormControl(
              '',
              Validators.pattern(this.pattern)
            ),
            description: new FormControl('', [
              Validators.required,
              Validators.maxLength(4000),
            ]),
            dateOfOccurrence: new FormControl('', [Validators.required]),
            complainant: new FormGroup({
              name: new FormControl(data.azi_fullName),
              identificationNumber: new FormControl(''),
              email: new FormControl(data.email, [
                Validators.required,
                this.emailValidator,
              ]),
            }),
          });
        }
      });
    }

    if (this.codeCmd) {
      this.authorizationService.getUserInfoCmd(this.codeCmd).subscribe(data => {
        if (this.internal) {
          this.complaintForm = new FormGroup<any>({
            recaptchaReactive: new FormControl(null, Validators.required),
            anonymous: new FormControl(false, Validators.required),
            internal: new FormControl(true, [Validators.required]),
            area: new FormControl('', [Validators.required]),
            peopleInvolved: new FormControl(
              '',
              Validators.pattern(this.pattern)
            ),
            description: new FormControl('', [
              Validators.required,
              Validators.maxLength(4000),
            ]),
            dateOfOccurrence: new FormControl('', [Validators.required]),
            complainant: new FormGroup({
              name: new FormControl(data.fullName),
              identificationNumber: new FormControl(data.nic),
              email: new FormControl('', [
                Validators.required,
                this.emailValidator,
              ]),
            }),
          });
        } else {
          this.complaintForm = new FormGroup<any>({
            recaptchaReactive: new FormControl(null, Validators.required),
            anonymous: new FormControl(false, Validators.required),
            internal: new FormControl(false, [Validators.required]),
            domain: new FormControl('', [Validators.required]),
            peopleInvolved: new FormControl(
              '',
              Validators.pattern(this.pattern)
            ),
            description: new FormControl('', [
              Validators.required,
              Validators.maxLength(4000),
            ]),
            dateOfOccurrence: new FormControl('', [Validators.required]),
            complainant: new FormGroup({
              name: new FormControl(data.fullName),
              identificationNumber: new FormControl(data.nic),
              email: new FormControl('', [
                Validators.required,
                this.emailValidator,
              ]),
            }),
          });
        }
      });
    }

    this.complaintForm
      .get('anonymous')
      ?.valueChanges.subscribe((value: boolean) => {
        if (value == true) {
          this.complaintForm.removeControl('complainant');
        } else {
          this.complaintForm.addControl(
            'complainant',
            new FormGroup({
              name: new FormControl(''),
              identificationNumber: new FormControl(''),
              email: new FormControl('', [
                Validators.required,
                this.emailValidator,
              ]),
            })
          );
        }
      });
  }

  handleFile(event: any) {
    const limitOfAttachments: number = 5 - this.attachments.length;
    if (limitOfAttachments === 0) return;
    const files = event.target.files as Array<any>;
    for (let file of files) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const fileReader = reader.result as string;
        const base64 = fileReader.split(',', 2)[1];
        const name = file.name;
        const lastDot = name.lastIndexOf('.');
        const fileName = name.substring(0, lastDot);
        const ext = name.substring(lastDot + 1);
        if (this.attachments.length < 5) {
          this.attachments.push({
            fileName: fileName,
            fileType: ext,
            base64: base64,
          });
        }
      };
    }
  }

  deleteFile(index: number) {
    this.attachments = [
      ...this.attachments.slice(0, index),
      ...this.attachments.slice(index + 1),
    ];
  }

  triggerFileInput() {
    const element = document.getElementById('file_upload') as HTMLElement;
    element.click();
  }

  searchAreas(event: any) {
    const group = event.target.value || null;
    if (!group) return;
    for (let groupEntry of this.groups) {
      if (groupEntry.uuid == group) {
        this.areas = groupEntry.areas;
      }
    }
    this.complaintForm.get('area')?.enable();
  }

  getDomains() {
    this.groups.forEach(group => {
      group.areas.forEach((area: any) => {
        this.domains = [...this.domains, ...area.domains];
      });
    });
  }

  submitComplainantData() {
    this.complaintForm.get('complainant')?.markAllAsTouched();
    if (
      !this.complaintForm.get('complainant') ||
      this.complaintForm.get('complainant')?.valid
    ) {
      this.complainantDataFilled = true;
    }
  }

  submitComplaint() {
    this.complaintForm.markAllAsTouched();
    if (this.complaintForm.invalid) return;
    const body = {
      ...this.complaintForm.value,
      attachments: this.attachments,
    };
    this.submiting = true;
    this.complaintService.create(body).subscribe(
      data => {
        this.submiting = false;
        this.followNumber = data.code;
      },
      () => {
        this.submiting = false;
      }
    );
  }

  openAutenticacaoGov() {
    const language = this.getLanguage();
    localStorage.setItem('portal_do_denunciante_idioma', language);
    localStorage.setItem(
      'portal_do_denunciante_tipo',
      this.complaintForm.get('internal')?.value ? 'interno' : 'externo'
    );
    window.open(environment.cmd, '_self');
  }

  openGraId() {
    const language = this.getLanguage();
    localStorage.setItem('portal_do_denunciante_idioma', language);
    localStorage.setItem(
      'portal_do_denunciante_tipo',
      this.complaintForm.get('internal')?.value ? 'interno' : 'externo'
    );
    localStorage.setItem('portal_do_denunciante_metodo', 'gra');
    window.open(environment.gra_id, '_self');
  }

  openAzorId() {
    const language = this.getLanguage();
    localStorage.setItem('portal_do_denunciante_idioma', language);
    localStorage.setItem(
      'portal_do_denunciante_tipo',
      this.complaintForm.get('internal')?.value ? 'interno' : 'externo'
    );
    localStorage.setItem('portal_do_denunciante_metodo', 'azorid');
    window.open(environment.azorid, '_self');
  }

  getLanguage(): string {
    const path = window.location.pathname;
    const splitPath = path.split('/');
    return splitPath[2] ?? 'pt';
  }

  emailValidator(control: any) {
    if (control.value) {
      const matches = control.value.match(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      );
      return matches ? null : { email: true };
    } else {
      return null;
    }
  }
}
