export const environment = {
  production: false,
  base_url: 'https://dev-denunciante.tech.augentic.com/v1/',
  gra_id:
    'https://id.azores.gov.pt/idp/profile/oidc/authorize?response_type=code&scope=openid,profile,sAMAccountName&client_id=dev-denunciante-app&redirect_uri=https://dev-denunciante.tech.augentic.com/portal',
  recaptcha: '6LdEoMMpAAAAACbMdMoOMvj69uNWgXJHcdA2o7Pe',
  cmd: 'https://preprod.autenticacao.gov.pt/oauth/askauthorization?client_id=8486890058233221609&response_type=token&scope=http://interop.gov.pt/MDC/Cidadao/NIC%20http://interop.gov.pt/MDC/Cidadao/NomeProprio%20http://interop.gov.pt/MDC/Cidadao/DataValidade%20http://interop.gov.pt/MDC/Cidadao/NomeApelido&redirect_uri=srv-denunciantedev01.dev.dnxt.int/portal',
  azorid:
    'https://dev.azorid.dnxt.pt/auth/realms/azorid-dnxt/protocol/openid-connect/auth?client_id=dev-denunciante-client&redirect_uri=https://dev-denunciante.tech.augentic.com/portal/complaint&response_type=code&scope=azi_email%20azi_fullName',
  azorid_enabled: true,
};
