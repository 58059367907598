<div class="footer">
  <div class="info">
    <div class="logo">
      <a href="https://recuperarportugal.gov.pt/"
        ><img
          src="assets/images/prr.svg"
          alt="prr"
          title="Plano de Recuperação e Resiliência"
      /></a>
    </div>
    <div class="logo">
      <a href="https://portal.azores.gov.pt/en/home"
        ><img
          src="assets/images/gra-logo-white.svg"
          alt="gra"
          title="Governo Regional dos Açores"
      /></a>
    </div>
    <div class="logo">
      <a href="https://www.portugal.gov.pt/"
        ><img
          src="assets/images/republica_portuguesa_branco_cores.svg"
          alt="republica"
          title="República Portuguesa"
      /></a>
    </div>
    <div class="logo">
      <a href="https://next-generation-eu.europa.eu/index_pt"
        ><img
          src="assets/images/finance_eu.svg"
          alt="finance_eu"
          title="Financiado pela União Europeia"
      /></a>
    </div>
    <div class="logo"><img src="assets/images/c19.svg" alt="republica" /></div>
  </div>
  <div class="divider"></div>
  <div class="info-below">
    <div class="gra">
      <img
        src="assets/images/gra-logo-white.svg"
        alt="logo"
        title="Governo Regional dos Açores" />
      <div i18n class="oficial">
        Esta é uma presença online oficial do Governo dos Açores.<br />Secretaria
        Regional das Finanças, Planeamento e Administração Pública
      </div>
    </div>
  </div>
  <div class="divider"></div>
  <div class="terms">
    <p i18n [routerLink]="['/terms-and-conditions']">TERMOS E CONDIÇÕES</p>
    <p i18n>Copyright © Augentic Tech, LDA. Todos os direitos reservados</p>
    <p i18n [routerLink]="['/contacts']">CONTACTOS</p>
    <p i18n [routerLink]="['/privacy-policy']">POLÍTICA DE PRIVACIDADE</p>
    <a i18n href="https://recuperarportugal.gov.pt/">
      www.recuperarportugal.gov.pt
    </a>
  </div>
</div>
