<div class="complaint">
  <div class="header" *ngIf="!followNumber">
    <h1 i18n>Submeter denúncia</h1>
    <p i18n>
      Responda a todas as questões (sempre que possível) e faculte informação
      detalhada. Se desconhecer a informação solicitada, deixe o campo de
      resposta em branco.
    </p>
  </div>

  <form [formGroup]="complaintForm" class="complaint-form">
    <div class="step" *ngIf="!complainantDataFilled && !followNumber">
      <div class="border">
        <h3 i18n>Denunciante anónimo?</h3>

        <div class="radio">
          <input
            class="input"
            type="radio"
            name="anonymous"
            formControlName="anonymous"
            [value]="true" />
          <div i18n>Sim</div>
          <input
            class="input"
            type="radio"
            name="anonymous"
            formControlName="anonymous"
            [value]="false" />
          <div i18n>Não</div>
        </div>

        <div
          *ngIf="!complaintForm.get('anonymous')?.value"
          formGroupName="complainant">
          <div>
            <div class="autenticacao_gov">
              <button type="button" class="cmd" (click)="openAutenticacaoGov()">
                <img src="assets/images/autenticacao_gov.svg" />
              </button>

              <button type="button" class="gra_id" (click)="openGraId()">
                <img src="assets/images/gra_id.svg" />
              </button>

              <button
                *ngIf="azoridEnabled"
                type="button"
                class="azorid"
                (click)="openAzorId()">
                <img src="assets/images/azorid.svg" />
              </button>
            </div>

            <div class="divider">
              <div></div>
              <p i18n>ou</p>
              <div></div>
            </div>
            <fieldset>
              <label i18n>Nome do Denunciante</label>
              <input class="input" formControlName="name" type="text" />
            </fieldset>
            <fieldset>
              <label i18n>N.º de Identificação Civil</label>
              <input
                class="input"
                formControlName="identificationNumber"
                type="text" />
            </fieldset>
            <fieldset>
              <label i18n>Email*</label>
              <!-- prettier-ignore -->
              <input class="input"
                formControlName="email"
                type="text"
                [ngClass]="{
                  'error': !complaintForm.get('complainant')?.valid && complaintForm.get('complainant')?.touched
                }"/>
              <div
                class="error-label"
                *ngIf="
                  !complaintForm.get('complainant')?.get('email')?.valid &&
                  complaintForm.get('complainant')?.get('email')?.touched &&
                  !complaintForm.get('complainant')?.get('email')?.value
                "
                i18n>
                Este campo é de preenchimento obrigatório
              </div>
              <div
                class="error-label"
                *ngIf="
                  complaintForm.get('complainant')?.get('email')?.errors?.['email'] &&
                  complaintForm.get('complainant')?.get('email')?.touched && 
                  complaintForm.get('complainant')?.get('email')?.value
                "
                i18n>
                Email com formato inválido
              </div>
            </fieldset>
            <p i18n>* Campos de preenchimento obrigatório</p>
          </div>
        </div>
      </div>

      <button type="button" (click)="submitComplainantData()" i18n>
        Avançar <img src="assets/images/right-arrow.svg" alt="" />
      </button>
    </div>

    <div class="step" *ngIf="complainantDataFilled && !followNumber">
      <div class="border">
        <img
          src="assets/images/back.svg"
          class="back-button"
          (click)="complainantDataFilled = false" />
        <div>
          <h3 i18n>Dados da Denúncia</h3>

          <div *ngIf="complaintForm.get('internal')?.value">
            <div i18n class="internal-title">Denúncia Interna</div>
            <div i18n class="internal-description">
              A denúncia interna pode ser efetuada apenas por pessoas que
              integram o serviço/entidade sobre a qual recai a denúncia.
            </div>
          </div>

          <div *ngIf="!complaintForm.get('internal')?.value === true">
            <div i18n class="internal-title">Denúncia Externa</div>
            <div i18n class="internal-description">
              A denúncia externa pode ser efetuada por pessoas que integram o
              serviço/entidade ou por pessoas externas ao serviço/entidade sobre
              a qual recai a denúncia.
            </div>
          </div>

          <fieldset *ngIf="complaintForm.get('internal')?.value === true">
            <label i18n>Departamento*</label>
            <select (change)="searchAreas($event)">
              <option i18n value="" selected>Selecione um departamento</option>
              <option *ngFor="let group of groups" [value]="group.uuid">
                {{ group.name }}
              </option>
            </select>
          </fieldset>
          <fieldset *ngIf="complaintForm.get('internal')?.value">
            <label i18n>Entidade do Departamento*</label>
            <select
              [ngClass]="{
                error:
                  !complaintForm.get('area')?.valid &&
                  complaintForm.get('area')?.touched
              }"
              formControlName="area">
              <option i18n value="" disabled>Selecione uma entidade</option>
              <option *ngFor="let area of areas" [value]="area.uuid">
                {{ area.name }}
              </option>
            </select>
            <div
              i18n
              class="error-label"
              *ngIf="
                !complaintForm.get('area')?.valid &&
                complaintForm.get('area')?.touched
              ">
              Este campo é de preenchimento obrigatório
            </div>
          </fieldset>
          <fieldset *ngIf="complaintForm.get('internal')?.value === false">
            <label i18n>Domínio*</label>
            <select
              [ngClass]="{
                error:
                  !complaintForm.get('domain')?.valid &&
                  complaintForm.get('domain')?.touched
              }"
              formControlName="domain">
              <option i18n value="" disabled>Selecione um domínio</option>
              <option *ngFor="let domain of domains" [value]="domain.uuid">
                {{ domain.name }}
              </option>
            </select>
            <div
              i18n
              class="error-label"
              *ngIf="
                !complaintForm.get('domain')?.valid &&
                complaintForm.get('domain')?.touched
              ">
              Este campo é de preenchimento obrigatório
            </div>
          </fieldset>
          <fieldset>
            <label i18n>Pessoas envolvidas</label>
            <input
              [ngClass]="{
                error:
                  !complaintForm.get('peopleInvolved')?.valid &&
                  complaintForm.get('peopleInvolved')?.touched
              }"
              class="input"
              type="text"
              formControlName="peopleInvolved" />
            <div
              i18n
              class="error-label"
              *ngIf="!complaintForm.get('peopleInvolved')?.valid">
              Este campo apenas aceita letras e caracteres especiais.
            </div>
          </fieldset>
          <fieldset>
            <label i18n>Relato da denúncia*</label>
            <textarea
              [ngClass]="{
                error:
                  !complaintForm.get('description')?.valid &&
                  complaintForm.get('description')?.touched
              }"
              formControlName="description"></textarea>
            <div
              class="caracters"
              [ngClass]="{
                red:
                complaintForm.get('description')?.value &&
                complaintForm.get('description')?.touched &&
                complaintForm.get('description')?.errors?.['maxlength']
              }">
              {{ complaintForm.get('description')?.value.length }}/4000
            </div>
            <div
              i18n
              class="error-label"
              *ngIf="
                !complaintForm.get('description')?.valid &&
                complaintForm.get('description')?.touched &&
                !complaintForm.get('description')?.value
              ">
              Este campo é de preenchimento obrigatório
            </div>
            <div
              i18n
              class="error-label"
              *ngIf="
              complaintForm.get('description')?.value &&
                complaintForm.get('description')?.touched &&
                complaintForm.get('description')?.errors?.['maxlength']
              ">
              Apenas são permitidos 4000 caracteres
            </div>
          </fieldset>
          <div style="margin-bottom: 15px">
            <label i18n>Data do acontecimento*</label>
            <mat-form-field appearance="fill" class="custom-class">
              <input
                formControlName="dateOfOccurrence"
                matInput
                [max]="today"
                [matDatepicker]="picker" />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <div
              i18n
              class="error-label"
              *ngIf="
                !complaintForm.get('dateOfOccurrence')?.valid &&
                complaintForm.get('dateOfOccurrence')?.touched
              ">
              Este campo é de preenchimento obrigatório
            </div>
          </div>
          <fieldset>
            <label i18n>Anexos</label>
            <p i18n style="margin-top: 0">
              (São aceites até 5 ficheiros .png, .jpeg, .jpg, .pdf, .mp3 e .mp4.
              Cada ficheiro pode ter no máximo 20MB.)
            </p>
            <div class="upload-box" *ngIf="attachments.length < 5">
              <img
                src="assets/images/upload-file.svg"
                alt="upload"
                class="upload"
                (click)="triggerFileInput()" />
            </div>
            <input
              style="display: none"
              id="file_upload"
              nbInput
              fullWidth
              type="file"
              multiple
              accept="image/png, image/jpg, image/jpeg, application/pdf, .mp3, .mp4, .m4v, .MPEG-4, MPEG"
              (change)="handleFile($event)" />
            <div class="attachments-list">
              <div
                *ngFor="let file of attachments; let i = index"
                class="attachment">
                <div class="attachment-name">
                  {{ file.fileName }}.{{ file.fileType }}
                </div>
                <img
                  class="delete-attachment"
                  src="assets/images/close.svg"
                  (click)="deleteFile(i)" />
              </div>
            </div>
          </fieldset>
          <fieldset>
            <re-captcha formControlName="recaptchaReactive"></re-captcha>
          </fieldset>
          <p i18n>* Campos de preenchimento obrigatório</p>
        </div>
      </div>

      <button
        i18n
        type="button"
        (click)="submitComplaint()"
        [disabled]="submiting">
        Submeter denúncia
        <mat-spinner
          *ngIf="submiting"
          color="primary"
          diameter="25"></mat-spinner>
      </button>
    </div>
  </form>

  <div class="follow" *ngIf="followNumber">
    <img src="assets/images/follow_check_green.svg" />
    <h1>Obrigado!</h1>
    <p class="success_label">
      A sua denúncia foi submetida com sucesso. Poderá acompanhar o estado da
      sua denúncia através do código disponibilizado abaixo.
    </p>
    <p class="follow_number">{{ followNumber }}</p>
  </div>
</div>
