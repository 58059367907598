<div class="welcome">
  <div class="welcome-message-container">
    <h1 i18n>Bem-vindo</h1>
    <h3 i18n>ao Canal de Denúncia do Governo Regional dos Açores</h3>
    <p i18n>
      Encorajamo-lo(a) a denunciar qualquer infração ou suspeita de infração
      neste canal de denúncias. Asseguramos sempre o anonimato e a
      confidencialidade da sua identidade, desde a submissão da denúncia até à
      conclusão do processo.
    </p>
    <div class="options">
      <button i18n [matTooltip]="entityText" (click)="complaint(true)">
        Criar denúncia interna
      </button>
      <button i18n [matTooltip]="complaintText" (click)="complaint(false)">
        Criar denúncia externa
      </button>
    </div>
  </div>
  <img class="welcome-image" src="assets/images/welcome-image.png" />
</div>
