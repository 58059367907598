import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  constructor(private http: HttpClient) {}

  getUserInfo(token: string): Observable<any> {
    return this.http.post<any>(
      `${environment.base_url}authorization/gra/portal/login`,
      { token }
    );
  }

  getUserInfoCmd(token: string): Observable<any> {
    return this.http.post<any>(
      `${environment.base_url}authorization/cmd/portal/login`,
      { token }
    );
  }

  getUserInfoAzorid(token: string): Observable<any> {
    return this.http.post<any>(
      `${environment.base_url}authorization/azorid/portal/login`,
      { token }
    );
  }
}
