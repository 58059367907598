<h1 i18n>Circuito da Denúncia</h1>

<div class="process">
  <div class="step">
    <img src="assets/images/submit-complaint.png" alt="submit-complaint" />
    <p i18n class="title">Submeter a Denúncia</p>
    <p i18n class="description">
      Preenchimento dos seus dados e dos dados relativos à sua denúncia.
    </p>
  </div>
  <div class="step">
    <img src="assets/images/manage-complaint.png" alt="manage-complaint" />
    <p i18n class="title">Gestor de Denúncia</p>
    <p i18n class="description">
      A sua denúncia será enviada para um gestor que a encaminhará para o
      profissional responsável.
    </p>
  </div>
  <div class="step">
    <img src="assets/images/follow-complaint.png" alt="follow-complaint" />
    <p i18n class="title">Acompanhe a sua Denúncia</p>
    <p i18n class="description">
      Receberá um código para que possa acompanhar o estado da sua Denúncia
      <a [routerLink]="['/follow']">aqui</a>.
    </p>
  </div>
</div>
